import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'
import React from 'react'

export default function ContentSlice({ filter }) {
  const data = useStaticQuery(graphql`
    query DocumentIndexQuery {
      allContentfulDocument {
        nodes {
          type
          validFrom(formatString: "MMMM Do, YYYY")
          content {
            raw
          }
        }
      }
    }
  `)

  const content = get(data, 'allContentfulDocument.nodes', null)

  const document = content.find((item) => item.type === filter)

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline text-uforange-600">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 className="font-bold my-4">{children}</h1>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className="font-bold my-4">{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 className="font-bold my-4">{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className="my-4">{children}</p>
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <table className="border-collapse border">
            <tbody>{children}</tbody>
          </table>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        return <tr>{children}</tr>
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return <td className="border px-4 py-2">{children}</td>
      },
    },
  }

  return (
    <div className="relative overflow-hidden bg-white py-32">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-uforange-600">
              {document.validFrom}
            </span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight capitalize text-ufblack sm:text-4xl">
              {document.type} Policy
            </span>
          </h1>
        </div>
        <div className="max-w-prose prose prose-lg prose-orange mx-auto mt-12 text-ufblack">
          {document.content?.raw && renderRichText(document.content, options)}
        </div>
      </div>
    </div>
  )
}
